<template>
  <div class="newsList">
    <Swiper></Swiper>
    <div class="search">
      <input
        class="searchInput"
        placeholder="搜索"
        type="text"
        v-model="searchMess"
      />
      <div class="line"></div>
      <!-- -->
      <img
        class="glass"
        :src="glassImg"
        alt=""
        @click="searchNews(searchMess)"
      />
    </div>
    <!--   -->
    <el-tabs
      :tab-position="tabPosition"
      style="height: 5.6rem;"
      v-model="activeCount.data"
      @tab-click="tabClick"
    >
      <el-tab-pane label="小新快讯" name="1">
        <div
          class="littleMess"
          @click="goDetail(item.id)"
          v-for="(item, i) in mess.data"
          :key="i"
        >
          <img class="img" :src="item.img" alt="" />
          <div class="right">
            <p class="mess">{{ item.title }}</p>
            <p>{{ item.issue_time.slice(0, 10) }}</p>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            page-size="5"
            :total="count.data"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            v-model:currentPage="currentPage1"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="行业动态" name="2">
        <div
          class="news"
          @click="goDetail(item.id)"
          v-for="(item, i) in active.data"
          :key="i"
        >
          <img class="img" :src="item.img" alt="" />
          <div class="right">
            <p class="mess">{{ item.resume }}</p>
            <p>{{ item.issue_time.slice(0, 10) }}</p>
          </div>
        </div>
        <div class="page">
          <el-pagination
            page-size="5"
            background
            layout="prev, pager, next"
            :total="count2.data"
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            v-model:currentPage="currentPage2"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="实践校" name="3">
        <div
          class="news"
          @click="goDetail(item.id)"
          v-for="(item, i) in school.data"
          :key="i"
        >
          <img class="img" :src="item.img" alt="" />
          <div class="right">
            <p class="mess">{{ item.resume }}</p>
            <p>{{ item.issue_time.slice(0, 10) }}</p>
          </div>
        </div>
        <div class="page">
          <el-pagination
            page-size="5"
            background
            layout="prev, pager, next"
            :total="count3.data"
            @size-change="handleSizeChange3"
            @current-change="handleCurrentChange3"
            v-model:currentPage="currentPage3"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- <SubNav></SubNav> -->
    <!-- <div class="news">
      <MiddleBox></MiddleBox>
      <div class="right">
        <long-box></long-box>
      </div>
    </div> -->
    <!-- 小新快讯 和 行业动态动态标签 -->
    <!-- <div class="list">
      <p @click="goDetail(111)">去详情!!!!!!!!!!!</p>
    </div> -->
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref  } from "vue";
import axios from "@/utils/axios";

import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";
import { onBeforeRouteUpdate } from "vue-router";
import { useRoute } from 'vue-router'
export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
  },
  data() {
    return {
      tabPosition: "left",
      glassImg: require("@/assets/1new/赋能工程/2.jpg"),
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      activeTab: '3',
      messList: [
        {
          id: 1,
          mess: "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess: "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
    };
  },
  methods: {
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
      // window.open(href, "_blank");
    },
    // handleSizeChange(val: any) {
    //   console.log(`每页 ${val} 条`);
    // },

    // handleSizeChange2(val: any) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange2(val: any) {
    //   console.log(`当前页: ${val}`);
    // },
  },
  setup() {
    var count = reactive({ data: "" });
    var count2 = reactive({ data: "" });
    var count3 = reactive({ data: "" });
    var activeCount = reactive({ data: "3" });
    var searchMess = ref();
    var mess = reactive({ data: "" });
    var active = reactive({ data: "" });
    var school = reactive({ data: "" });
    const route = useRoute()
    onMounted(() => {
      var id:any = route.params
      console.log('路由导航守卫',id)
      switch( id ){
        case 1:
       activeCount.data = '1'
        break;
           case 2:
       activeCount.data = '2'
        break;
           case 3:
       activeCount.data = '3'
        break;
      }
    })
    // 小新快讯 1 行业动态2  实践校3
    axios
      .get(
        "https://www.xsy985.com/api/news/news?page=1&page_size=5&news_type=1"
      )
      .then(function (response) {
        // console.log(response.data, "资讯");
        mess.data = response.data.results;
        count.data = response.data.count;
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        "https://www.xsy985.com/api/news/news/?page=1&page_size=5&news_type=2"
      )
      .then(function (response) {
        // console.log(response.data,"动态");
        count2.data = response.data.count;
        active.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
       axios
      .get(
        "https://www.xsy985.com/api/news/news/?page=1&page_size=5&news_type=3"
      )
      .then(function (response) {
        // console.log(response.data,"动态");
        count3.data = response.data.count;
        school.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
    //  新闻资讯翻页
    const handleCurrentChange = (val: any) => {
      console.log(` ${val} ye`);
      axios
        .get(
          `https://www.xsy985.com/api/news/news?page=${val}&page_size=5&news_type=1`
        )
        .then(function (response) {
          // console.log(response.data);
          mess.data = response.data.results;
          window.scrollTo(0, 300);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    //  动态翻页
    const handleCurrentChange2 = (val: any) => {
      console.log(` ${val} ye`);
      axios
        .get(
          `https://www.xsy985.com/api/news/news?page=${val}&page_size=5&news_type=2`
        )
        .then(function (response) {
          // console.log(response.data);
          active.data = response.data.results;
          window.scrollTo(0, 300);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
       const handleCurrentChange3 = (val: any) => {
      console.log(` ${val} ye`);
      axios
        .get(
          `https://www.xsy985.com/api/news/news?page=${val}&page_size=5&news_type=3`
        )
        .then(function (response) {
          // console.log(response.data);
          school.data = response.data.results;
          window.scrollTo(0, 300);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    // 搜索
    const searchNews = (val: any) => {
      console.log(val, "搜索内容");
      axios
        .get(
          `https://www.xsy985.com/api/news/searchnews/?page=1&title=${val}`
        )
        .then(function (response) {
          console.log(response.data, "搜索结果");
          mess.data = response.data.results;
          active.data = response.data.results;
          if (response.data.count == 0) {
            mess.data = "暂无";
            active.data = "暂无";
          }
          window.scrollTo(0, 0);
        })
        .catch(function (error) {
          console.log(error);
        });
      // 搜索

    };

    return {
      mess,
      active,
      school,
      count,
      count2,
      count3,
      handleCurrentChange,
      handleCurrentChange2,
      handleCurrentChange3,
      searchNews,
      searchMess,
      activeCount,
    };
  },
});
</script>
<style lang="scss">
.newsList {
  .search {
    width: 1200px;
    height: 74px;
    margin-bottom: 30px;
    border-bottom: 1px solid #3ebbbd;
    position: relative;
    .searchInput {
      width: 545px;
      height: 48px;
      border-radius: 24px;
      border: none;
      background-color: #f4f4f4;
      position: absolute;
      right: 25px;
      top: 13px;
      overflow: hidden;
      border: none;
      outline: none;
      font-size: 24px;
      color: #999;
      padding-left: 16px;
    }
    .glass {
      width: 37px;
      height: 37px;
      position: absolute;
      top: 22px;
      right: 48px;
      cursor: pointer;
    }
    .line {
      width: 3px;
      height: 37px;
      background-color: #3ebbbd;
      position: absolute;
      top: 22px;
      right: 92px;
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #00a5a8; //修改后的背景图颜色
    color: #fff;
  }
  width: 1200px;
  background-color: #fff;
  // .el-tabs__nav-wrap::after {
  //   height: 1px;
  // }
  .el-tabs__item {
    height: 50px;
    font-size: 28px;
    font-weight: bold;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333;
  }
  .el-tabs__item:hover {
    color: #00a5a8;
  }
  .el-tabs__item.is-active {
    color: #00a5a8;
  }
  .el-tabs__active-bar {
    background: #00a5a8;

    /* width: 30px; */
  }

  .message {
    width: 1200px;
    // background-color: skyblue;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 56px;
  }
  .littleMess {
    width: 770px;
    height: 154px;
    margin-left: 120px;
    margin-bottom: 51px;
    .img {
      width: 250px;
      height: 154px;
      float: left;
      background-color: #777;
    }
    .right {
      float: left;
      width: 380px;
      height: 100px;
      margin-left: 110px;
      margin-top: 14px;
      .mess {
        border-bottom: 1px dashed #00a5a8;
        padding-bottom: 15px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
  }
  .news {
    width: 770px;
    height: 154px;
    margin-left: 120px;
    margin-bottom: 51px;
    .img {
      width: 250px;
      height: 154px;
      float: left;
      background-color: #777;
    }
    .right {
      float: left;
      width: 380px;
      height: 100px;
      margin-left: 110px;
      margin-top: 14px;
      .mess {
        border-bottom: 1px dashed #00a5a8;
        padding-bottom: 15px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
  }
  .page {
    float: right;
  }
}
</style>
